





















































































































































































































































































import TaskPrintComponent from "@/components/plannerComponent/components/taskPrintComponent/TaskPrintComponent";
export default TaskPrintComponent;
